#section-5 {
  .main {
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 32px;
    }
    .content {
      margin-top: 16px;
      .main-text {
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .list-text {
        li {
          @media only screen and (max-width: 768px) {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
    .heading {
      .heading-text {
        @media only screen and (max-width: 768px) {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
