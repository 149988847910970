#section-10 {
  .main {
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 32px;
    }
    .heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .one {
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        p {
          font-size: 18px;
          color: #f1e2ec;
        }
      }

      .two {
        font-weight: unset;
        font-size: 18px;
        line-height: 32px;
        color: #f1e2ec;
        white-space: nowrap;
        @media only screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .content {
      margin-left: 35px;
      @media only screen and (max-width: 768px) {
        margin-left: 16px;
      }
      .container {
        .desc-txt {
          p {
            margin-top: 32px;
            font-weight: unset;
            font-size: 18px;
            line-height: 24px;
            color: rgba(241, 236, 226, 0.7);
            text-align: start;
            overflow-wrap: break-word;

            @media only screen and (max-width: 768px) {
              font-size: 18px;
              margin-top: 16px;
            }
          }
        }

        .input-cont {
          margin-top: 32px;
          width: 25%;
          @media only screen and (max-width: 768px) {
            width: 50%;
          }
          .input-field {
            input {
              display: none;
            }
            margin-top: 16px;

            .label-cont {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border: 1px solid #bb1ccc;
              padding: 8px;
              border-radius: 4px;
              cursor: pointer;
              background: rgba(241, 236, 226, 0.1);
              &:hover {
                background: rgba(241, 236, 226, 0.3);
              }

              .label-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                span {
                  padding: 4px 8px;
                  border: 1px solid #bb1ccc;
                  border-radius: 4px;
                  font-weight: 600;
                  color: #e27bed;
                }

                p {
                  font-size: 20px;
                  font-weight: 500;
                  color: #e27bed;
                }
              }
            }
            input:checked + .label-cont {
              border: 2.5px solid #e27bed;

              .label-content {
                span {
                  background: #f1e2ec;
                  color: #bb1ccc;
                }
              }
            }
          }
          .blinking-text {
            animation: blink 0.3s 2;
          }
        }
      }
      .password-txt {
        margin-top: 16px;
        font-weight: unset;
        font-size: 16px;
        line-height: 20px;
        color: rgb(241, 236, 226);
        font-family: sans-serif;
        opacity: 0.7;

        a {
          font-weight: unset;
          font-size: 16px;
          line-height: 20px;
          color: rgb(241, 236, 226);
          font-family: sans-serif;
          opacity: 0.7;
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
