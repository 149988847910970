.section-date {
  position: relative;
  .header-content {
    span {
      font-size: 18px;
      font-weight: normal;
      color: #f1e2ec;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(44, 44, 44, 0.9);
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    animation: moveDown 0.3s ease-in-out forwards;

    .header-text {
      font-weight: unset;
      font-size: 26px;
      line-height: 32px;
      color: #f1e2ec;
      white-space: nowrap;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .main {
    margin-left: 35px;
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 32px;
      margin-left: 0px;
    }
    .heading {
      margin-bottom: 32px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      color: #f1e2ec;
      margin-left: 40px;

      .one {
        font-size: 20px;
      }
      .two {
        line-height: 32px;
        font-size: 18px;
      }
    }
  }

  .content {
    margin-left: 40px;
    .container {
      .input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }

        .react-datepicker__tab-loop {
          .react-datepicker-popper {
            transform: translate(237.5px, 215.5px) !important;
            @media only screen and (max-width: 768px) {
              transform: translate(95.5px, 163.5px) !important;
            }
          }
        }

        .input-field {
          label {
            display: block;
            margin-bottom: 16px;
            font-weight: unset;
            font-size: 18px;
            line-height: 20px;
            color: rgb(241, 236, 226);
          }

          input {
            width: 100%;
            padding: 16px 8px 16px 0px;
            outline: none;
            border: none;
            border-bottom: 1px solid rgb(241, 236, 226, 0.7);
            background: transparent;
            color: #f1e2ec;
            font-size: 18px;
            transition: all 0.1s;
          }

          input:focus {
            border-bottom: 3px solid #bb1ccc;
          }
        }

        .day,
        .month {
          width: 20%;
        }
        .year {
          width: 30%;
        }

        .date-dot {
          font-size: 24px;
          font-weight: 600;
          align-self: self-end;
          padding-bottom: 20px;
        }
      }
    }
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-50px);
  }

  to {
    transform: translateY(0px);
  }
}
