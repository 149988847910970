#section-12 {
  animation: moveUp 0.4s linear forwards;
  .main {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    .heading {
      border: 1px solid #bb1ccc;
      padding: 24px;
      background-color: #bb1ccc;
      margin-bottom: 50px;
      animation: bounceEffect 0.7s 0.5s linear forwards;
      svg {
        font-size: 80px;
        fill: #fff;
      }
    }

    .thanks-txt,
    .aays-txt {
      font-weight: unset;
      font-size: 28px;
      line-height: 32px;
      color: rgb(241, 236, 226);
      overflow-wrap: break-word;
      @media only screen and (max-width: 768px) {
       font-size: 20px;
      }
    }

    .aays-txt {
      margin-top: 16px;
      @media only screen and (max-width: 768px) {
        margin-top: 8px;
      }
      a {
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

@keyframes bounceEffect {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes moveUp{
  from { 
    opacity: 0;
  }
   to{
    opacity: 1;
   }
}
