* {
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body{
  width: 100%;
  height: 100vh;
}

body::-webkit-scrollbar{
  display: none;
}

#root{
  width: 100%;
  height: 100%;
}

#root .App{
  width: 100%;
  height: 100%;
}

html{
  scroll-snap-type: y mandatory;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}