.button-box-cls {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 2rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }

  .button-box {
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    button {
      outline: none;
      border: none;
      font-size: 18px;
      padding: 10px 15px;
      font-weight: bold;
      border-radius: 5px;
      background-color: #bb1ccc;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #ce23e1;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }
    }

    span {
      color: #fff !important;
      font-size: 18px !important;
    }
  }
}

.mobile-btn {
  @media only screen and (max-width: 768px) {
    position: absolute;
    bottom: 6%;
    padding: 0px 16px;
    animation: sectionToUp 0.7s linear forwards;
  }
}

.section {
  background-color: #212121;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  .form-styling {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main {
    width: 50%;
    color: white;
    animation: sectionToUp 0.4s linear forwards;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 32px 0px 16px;
    }
  }

  .animateDown {
    animation: animateDown 0.4s linear forwards !important;
  }
}

#landingPage {
  scroll-snap-align: start;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: 100vh;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .left {
      position: relative;
      background-color: #212121;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      @media only screen and (max-width: 768px) {
        justify-content: flex-end;
        padding: 12px;
        width: 100%;
      }

      .box {
        .main-heading {
          font-size: 24px;
          font-weight: bold;
          @media only screen and (max-width: 768px) {
            width: 80%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
          }
        }

        .third {
          display: flex;
          gap: 5px;
          align-items: center;
          margin-top: 1rem;
        }

        animation: toUp 0.7s linear forwards;

        @media only screen and (max-width: 768px) {
          height: 100%;
        }
        .landing-form {
          .button-cont {
            @media only screen and (max-width: 768px) {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
            }
          }
          @media only screen and (max-width: 768px) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .right {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: visibleImage 1s linear forwards;
      }
    }
  }
}

@keyframes toUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes visibleImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#section-1 {
  .main {
    animation: sectionToUp 0.7s linear forwards;
    .heading {
      display: flex;
      align-items: center;
      gap: 10px;
      .one {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      .two {
        font-size: 18px;
      }
    }

    .content {
      margin-left: 40px;

      .name {
        height: 40px;
        width: 100%;
        background: transparent;
        color: white;
        border: none;
        border-bottom: 1px solid gray;
        font-size: 18px;
        margin-top: 2rem;
      }

      .name:focus {
        outline: none;
        border-bottom: 3px solid #bb1ccc;
      }
    }
  }
}

@keyframes sectionToUp {
  from {
    transform: translateY(700px);
  }

  to {
    transform: translateY(0);
  }
}

#section-2 {
  .main {
    .heading {
      display: flex;
      align-items: center;
      gap: 10px;
      .one {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      .two {
        font-size: 18px;
      }
    }
    .content {
      margin-left: 40px;
      margin-top: 32px;

      .container {
        .input-field {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          .country-code {
            width: 100%;
            max-width: 175px;

            @media only screen and (max-width: 768px) {
              width: 50%;
            }
            .css-13cymwt-control,
            .css-t3ipsp-control {
              background: transparent;
              border: none;
              border-bottom: 3px solid gray;
              border-radius: 0;
              margin-right: 16px;
              top: 2px;
              color: gray;

              .css-1dimb5e-singleValue {
                color: #f1e2ec;
                font-size: 18px;
              }
            }

            .css-t3ipsp-control {
              border-bottom: 3px solid #bb1ccc;
              box-shadow: none;
            }

            .css-t3ipsp-control:hover {
              border-color: #bb1ccc !important;
            }

            .css-1nmdiq5-menu {
              width: 130%;
              background: #3b3b3b;
              border: 2px solid gray;
              border-radius: 4px;

              .css-d7l1ni-option {
                color: #f1e2ec;
                background-color: #636363;
              }
            }

            .css-166bipr-Input {
              color: #f1e2ec;
            }
          }

          .countries {
            width: 15%;
            height: 40px;
            background: transparent;
            color: white;
            border: none;
            border-bottom: 1px solid gray;
            font-size: 18px;

            option {
              background-color: #262626;
              color: white;
              border-radius: 0%;
            }
            option:hover {
              background-color: red !important;
            }
          }
          .phone {
            height: 40px;
            width: 80%;
            background: transparent;
            color: #f1e2ec;
            border: none;
            border-bottom: 1px solid gray;
            font-size: 18px;
          }

          .countries:focus,
          .phone:focus {
            border: none;
            outline: none;
            border-bottom: 3px solid #bb1ccc;
          }
        }
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

#section-3 {
  background-color: #262626;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    .heading {
      font-size: 24px;
      margin-bottom: 1rem;
    }
    .content {
      font-size: 20px;
      color: #9fabab;
    }
  }
}

#section-6 {
  height: 100%;
  padding: 32px 0px;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }

  .main {
    overflow-y: scroll;
    height: 100%;
    .heading {
      display: flex;
      align-items: center;
      gap: 10px;
      .one {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      .two {
        font-size: 18px;
      }
    }
    .content {
      margin-left: 35px;
      .text {
        color: #9fabab;
        font-size: 18px;
        margin-bottom: 2rem;
      }
      .input-boxes {
        margin-top: 16px;
        .box {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;

          label {
            font-size: 16px;
          }
          input {
            height: 40px;
            width: 100%;
            background: transparent;
            color: white;
            border: none;
            border-bottom: 1px solid gray;
            font-size: 18px;
            margin-top: 2px;
          }
          input:focus {
            outline: none;
            border-bottom: 3px solid #bb1ccc;
          }
        }
      }
    }

    &::-webkit-scrollbar{
      width: 1vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bb1ccc;
      border-radius: 10px;
      height: 1vh;
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
    }

    @supports not (scrollbar-color: #ddd #eee) {
      scrollbar-color: #0000a0 #dfdfe5;
      scrollbar-width: normal;
    }
  }
}

#section-7 {
  .main {
    .heading {
      display: flex;
      align-items: center;
      gap: 10px;
      .one {
        display: flex;
        align-items: center;
        gap: 2px;
      }
      .two {
        font-size: 18px;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
    .content {
      margin-left: 35px;
      margin-top: 16px;
      .text {
        color: #9fabab;
        font-size: 18px;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}

// Common Styling for Para Sections
.heading {
  &-text {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    font-weight: 500;
    color: #f1e2ec;
  }
}
.content {
  .container {
    .description-text {
      .main-text {
        font-weight: unset;
        font-size: 18px;
        line-height: 24px;
        color: rgba(241, 236, 226, 0.7);
        text-align: start;
        overflow-wrap: break-word;
      }
    }

    .list-text {
      list-style: none;
      li {
        font-weight: unset;
        font-size: 18px;
        line-height: 28px;
        color: rgba(241, 236, 226, 0.7);
        text-align: start;
        overflow-wrap: break-word;
        margin-top: 32px;
      }
    }
  }
}

// Form Header and Footer Styling

#form {
  position: relative;
  height: 100vh;

  .Header {
    font-size: 24px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .Footer {
    width: 100%;
    max-width: 254px;
    position: fixed;
    bottom: 3%;
    right: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 768px) {
      bottom: 1%;
      right: 8%;
    }
    .footer-button-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .footer-icon {
        background-color: #bb1ccc;
        padding: 2px 4px;
        cursor: pointer;
        border: none;

        .up-icon,
        .down-icon {
          fill: #fff;
          font-size: 32px;
        }

        &:disabled {
          .up-icon,
          .down-icon {
            fill: #fff;
          }
          background-color: #e27bed;
          cursor: auto;
        }
      }
      .border-right-icon {
        border-right: 1px solid #212121;
        border-radius: 4px 0px 0px 4px;
      }

      .border-left-icon {
        border-radius: 0px 4px 4px 0px;
      }
    }

    .brand-container {
      background-color: #bb1ccc;
      padding: 9px 12px;
      cursor: pointer;
      border: none;
      text-decoration: none;
      border-radius: 4px;
      @media only screen and (max-width: 768px) {
        background-color: transparent;
      }
      .brand-txt {
        font-size: 18px;
        color: #fff;
        @media only screen and (max-width: 768px) {
          color: #fff;
        }
        span {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  // Progress Bar Styling
  .progress-bar {
    z-index: 100;
    height: 6px;
    background-color: #bb1ccc;
    transition: width 0.3s linear;
  }
}

@keyframes animateDown {
  from {
    transform: translateY(-700px);
  }
  to {
    transform: translateY(0px);
  }
}

// Mobile Footer Icon
.mobile-footer-icon {
  position: absolute;
  bottom: 6%;
  left: 4%;
  background: #bb1ccc;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  animation: sectionToUp 0.4s linear forwards;

  .left-icon {
    fill: #fff;
  }
}
