.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  padding: 16px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #353535;
  .navbar-cont {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo-container {
  flex: 1;
}

.logo {
  width: 150px;
  height: 60px;
}

.nav-links {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  .link {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    margin: 0 32px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: color 0.3s;

    &:hover {
      color: #e27bed;
    }
    &.active {
      color: #e27bed;
    }
  }
}
